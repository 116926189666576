<template>
	<div class="gg-container">
		
		<div class="search-container" >
			<!--对应第三方图标的button样式-->
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerBar">
					<i class="iconfont el-icon-s-data" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">柱状图</span>
				</el-button>
			</div>
			
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerLine">
					<i class="iconfont el-icon-data-line" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">折线图</span>
				</el-button>
			</div>
			
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerPie">
					<i class="iconfont el-icon-pie-chart" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">饼图</span>
				</el-button>
			</div>
		</div>
		
		<div ref="recordReportBar" class="record-report-bar"
				 :style="{height: scrollerHeightBar}"></div>
		
		<div ref="recordReportLine" class="record-report-line"
				 :style="{height: scrollerHeightLine}"></div>
		
		<div ref="recordReportPie" class="record-report-pie"
				 :style="{height: scrollerHeightPie}"></div>
		
		<div class="search-container">
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerHideTable">
					<i class="iconfont el-icon-date" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">显示隐藏table表</span>
				</el-button>
			</div>
		</div>
		<div  v-if="isShowTable">
			<div class="search-container">
				<div class="search-container-fn-input">
					<label>月份：</label>
					<el-date-picker
						v-model="choiceDate"
						type="daterange"
						size="mini"
						value-format="yyyy-MM-dd HH-mm-ss "
						range-separator="至"
						start-placeholder="开始月份"
						end-placeholder="结束月份"
						@change="handleChangeDate">
					</el-date-picker>
				</div>
				<!--对应第三方图标的button样式-->
				<div class="search-container-fn-input" style="margin-left: auto;"
						 v-if="operateList.indexOf('export') > -1"
				>
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handlerExportExcel()">
						<i class="iconfont icon-daoru" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">导出</span>
					</el-button>
				</div>
			</div>
		<!----------------表格---------------->
		<el-table
			ref="report-table"
			border
			height="625"
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			@selection-change="handleSelectionChange"
			style="width: 100%;z-index:0">
			<el-table-column
				type="index"
				align="center"
				width="50">
			</el-table-column>
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column
				prop="created_at"
				label="日期"
				align="center"
				width="160">
			</el-table-column>
			<el-table-column
				prop="cost"
				label="消耗"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="total_order_count"
				label="单数"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="total_order_amount"
				label="金额"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="roi"
				label="下单投产"
				align="center"
			width="120">
			</el-table-column>
			<el-table-column
				prop="thread_avg"
				label="平均成本"
				align="center"
			width="120">
			</el-table-column>
			<el-table-column
				prop="order_avg"
				label="均客单价"
				align="center"
			width="120">
			</el-table-column>
			<el-table-column label="线索汇总" align="center">
				<el-table-column prop="thread_order_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="thread_order_amount" label="金额" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="电话汇总" align="center">
				<el-table-column prop="mobile_order_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="mobile_order_amount" label="金额" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="汇总" align="center">
				<el-table-column prop="total_order_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="total_order_amount" label="金额" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column
				prop="deliver_put_rate"
				label="发货投产"
				align="center">
			</el-table-column>
			<el-table-column
				prop="deliver_rate"
				label="发货率"
				align="center">
			</el-table-column>
			<el-table-column
				prop="deliver_avg"
				label="发货客单价"
				align="center">
			</el-table-column>
			<el-table-column label="升单汇总" align="center">
				<el-table-column prop="add_order_count" label="升单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="add_order_diff" label="差额" width="120" align="center">
				</el-table-column>
				<el-table-column prop="sign_order_amount" label="签收额" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="初购签收" align="center">
				<el-table-column prop="first_order_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="first_order_amount" label="金额" width="120" align="center">
				</el-table-column>
				<el-table-column prop="first_sign_rate" label="签收率" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="初购拒收" align="center">
				<el-table-column prop="first_return_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="first_return_amount" label="金额" width="120" align="center">
				</el-table-column>
				<el-table-column prop="first_return_rate" label="拒收率" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="复购" align="center">
				<el-table-column prop="again_order_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="again_order_amount" label="金额" width="120" align="center">
				</el-table-column>
				<el-table-column prop="again_order_rate" label="客单价" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="复购签收" align="center">
				<el-table-column prop="again_sign_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="again_sign_amount" label="金额" width="120" align="center">
				</el-table-column>
				<el-table-column prop="again_sign_rate" label="签收率" width="120" align="center">
				</el-table-column>
			</el-table-column>
			<el-table-column label="复购拒收" align="center">
				<el-table-column prop="again_return_count" label="单数" width="120" align="center">
				</el-table-column>
				<el-table-column prop="again_return_amount" label="金额" width="120" align="center">
				</el-table-column>
				<el-table-column prop="again_return_rate" label="拒收率" width="120" align="center">
				</el-table-column>
			</el-table-column>
		</el-table>
		<!----------------分页---------------->
		<!--<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
								@pagination="getList"/>-->
	</div>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getConsumeSign} from '@/api/ggkq';
import FileSaver from 'file-saver'
import {mapGetters, mapState} from 'vuex';
import XLSX from "xlsx";
export default {
  // 消耗签收
  name: "consumeSign",
  components: {
    // Pagination,
  },
  watch:{	// 监视数据改变,重新绘制Chart
    barData: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.barDataX = []
        this.barDataTotalY = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            this.barDataX.push(item.created_at)
            this.barDataTotalY.push(item.cost)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstanceBar.dispose()
          this._initChartBar()
        })
      },
      immediate: true,
      deep: true
    },

    lineData: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.lineDataX = []
        this.lineDataTotalY = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            this.lineDataX.push(item.created_at)
            this.lineDataTotalY.push(item.cost)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstanceLine.dispose()
          this._initChartLine()
        })
      },
      immediate: true,
      deep: true
    },

    PieDataOrigin: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.PieData = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            var temp = {
              value : item.cost,
              name: item.created_at
            }
            this.PieData.push(temp)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstancePie.dispose()
          this._initChartPie()
        })
      },
      immediate: true,
      deep: true
    },

  },
  mounted() {
    this._initChartBar()	//初始化Chart实例
    this._initChartLine()
    this._initChartPie()
    window.addEventListener("resize", this.chartInstanceBar.resize);	// 监听页面大小改变
    window.addEventListener("resize", this.chartInstanceLine.resize);	// 监听页面大小改变
    window.addEventListener("resize", this.chartInstancePie.resize);	// 监听页面大小改变
  },
  created () {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    this.getList()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.chartInstanceBar)	// 页面关闭时候移除监听
    window.removeEventListener('resize', this.chartInstanceLine)	// 页面关闭时候移除监听
    window.removeEventListener('resize', this.chartInstancePie)	// 页面关闭时候移除监听
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
    }),
  },
  data () {
    return {
      choiceDate: [],
      searchParams: {
        created_at: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      options: [],
      tableData: [],
      operateList: [],


      isShowTable:false,
      scrollerHeightBar: '484px',
      scrollerHeightLine: '0',
      scrollerHeightPie: '0',

      chartInstanceBar: null,
      barData: [],
      barDataOrigin: [],
      barDataX: [],	// 横轴数据
      barDataTotalY: [],	// 纵轴数据,总档案数
      //  barDataPresY: [12, 23 ,56, 11, 9, 43],

      chartInstanceLine: null,
      lineData: [],
      lineDataOrigin: [],
      lineDataX: [],
      lineDataTotalY: [],


      chartInstancePie: null,
      PieDataOriginOld: [],
      PieDataOrigin: [],
      PieData: [],
    }
  },
  methods: {
    _initChartBar() {
      const initOption = {
        color: ['#2362fb'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: ['总档案'],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          show: true,
          // axisLabel: {
          //   interval: 0,
          // },
          // data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
          data: this.barDataX
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '总档案',
          type: 'bar',
          barWidth: '30%', //柱图宽度
          data:  this.barDataTotalY
        },
          // {
          //   name: '电子病历',
          //   type: 'bar',
          //   barWidth: '20%', //柱图宽度
          //   data: this.barDataEmrY
          // }
        ]
      }
      this.chartInstanceBar = this.$echarts.init(this.$refs.recordReportBar)	// 通过DOM获取Echart实例
      this.chartInstanceBar.showLoading()
      this.chartInstanceBar.setOption(initOption)	// 初始化数据
      this.chartInstanceBar.hideLoading();
    },

    _initChartLine() {
      const initOption = {
        color: ['#2362fb'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: ['总档案'],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
          },
          data: this.barDataX
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '总档案',
          type: 'line',
          data:  this.barDataTotalY
        },
          // {
          //   name: '电子病历',
          //   type: 'bar',
          //   barWidth: '20%', //柱图宽度
          //   data: this.barDataEmrY
          // }
        ]
      }
      this.chartInstanceLine = this.$echarts.init(this.$refs.recordReportLine)	// 通过DOM获取Echart实例
      this.chartInstanceLine.showLoading()
      this.chartInstanceLine.setOption(initOption)	// 初始化数据
      this.chartInstanceLine.hideLoading();
    },

    _initChartPie() {
      const initOption = {
        color: ['#5470c6', '#91cc75', '#fac858','#ee6666', '#73c0de', '#3ba272','#fc8452', '#9a60b4', '#ea7ccc'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'item'
        },

        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          orient: 'horizontal',
          left: 'center'
        },
        series: [{
          type: 'pie',
          radius: '75%',
          data:  this.PieData
        },],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
      this.chartInstancePie = this.$echarts.init(this.$refs.recordReportPie)	// 通过DOM获取Echart实例
      this.chartInstancePie.showLoading()
      this.chartInstancePie.setOption(initOption)	// 初始化数据
      this.chartInstancePie.hideLoading();
    },
		
    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      // this.searchParams.page = this.listQuery.page
      // this.searchParams.page_size = this.listQuery.limit
      getConsumeSign(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.barData = JSON.parse(JSON.stringify(data.data.list))
          this.lineData = JSON.parse(JSON.stringify(data.data.list))
          this.PieDataOrigin = JSON.parse(JSON.stringify(data.data.list))
          // this.total = _data.total
          this.listQuery.limit = _data.per_page
          this.tableData = _data.list
          _data.total.created_at = "汇总"
          this.tableData.unshift(_data.total)
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleExport (excelName) {

        try {
          const $e = this.$refs['report-table'].$el
          let $table = $e.querySelector('.el-table__fixed')
          if(!$table) {
            $table = $e
          }

          const wb = XLSX.utils.table_to_book($table, {raw:true})
          const wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST:true, type: 'array'})
          FileSaver.saveAs(
            new Blob([wbout],{type: 'application/octet-stream'}),
            `${excelName}.xlsx`,
          )
        } catch (e) {
          if (typeof console !== 'undefined') console.error(e)
        }
      
    },
    handleChangeDate () {
      this.searchParams.start_at = []
      this.searchParams.end_at = []
      this.searchParams.created_at = ""
      console.log(this.choiceDate)
      var _tmp0
      var _tmp1
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0].substring(0, 10)
        _tmp1 = this.choiceDate[1].substring(0, 10)
        this.searchParams.created_at += _tmp0 + ","
        this.searchParams.created_at += _tmp1
      } else {

        this.searchParams.created_at = ""
      }
      this.getList('restPage')
    },

    handleSelectionChange(val) {
      console.log(val, "val");
      //去判断按钮的显示与隐藏
      this.multipleSelection = val;
      if(val.length == 0) {
        this.getList()
      } else {
        this.barData = val;
        this.lineData = val;
        this.PieDataOrigin = val;
      }

    },
    handlerBar() {
      this.scrollerHeightBar = '484px'
      this.scrollerHeightLine = '0px'
      this.scrollerHeightPie = '0px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerLine() {
      this.scrollerHeightBar = '0px'
      this.scrollerHeightLine = '484px'
      this.scrollerHeightPie = '0px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerPie() {
      this.scrollerHeightBar = '0px'
      this.scrollerHeightLine = '0px'
      this.scrollerHeightPie = '484px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerHideTable() {
      this.isShowTable = !this.isShowTable;
    },
  }
}
</script>

<style lang="scss" scoped>
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
